import cn from 'clsx'
import MSG from 'consts/Messages'
import React from 'react'
import { toast } from 'react-toastify'
import { checkIsStringAHtml } from 'utils/String'

const CTABlock = ({ className, titleClassName = '', posTitleClassName = '', cta }) => {
  const title = cta?.title || "Let's Go!"
  const preTitle = cta?.preTitle || ''
  // isFeature property tells the component that this is coming from features page and should render preTitle with background and rounded
  const isFeature = cta?.isFeature
  const postTitle = cta?.postTitle || ''
  const ctaOnClick =
    cta?.ctaOnClick || (() => toast.info(MSG.INFO.COMING_SOON, { toastId: 'coming-soon' }))
  const ctaText = cta?.ctaText || ''
  const ctaContent = cta?.ctaContent || ''
  const ctaList = cta?.ctaList || ''

  // Split postTitle by newlines to maintain line breaks
  const postTitleLines = postTitle?.split('\n') || []

  return (
    <div className={className}>
      <h3
        className={cn(
          'mb-4 text-sm font-normal lg:mb-8 lg:text-base',
          isFeature &&
            'flex h-8 w-8 items-center justify-center rounded-full bg-secondary font-extrabold text-white lg:!mb-4'
        )}>
        {preTitle}
      </h3>
      <h2 className={cn('mb-4 text-2xl font-semibold lg:text-4xl', titleClassName)}>
        {checkIsStringAHtml(title) ? <span dangerouslySetInnerHTML={{ __html: title }} /> : title}
      </h2>

      {postTitle && (
        <h3 className={cn('mb-4 text-lg font-normal lg:mb-8 lg:text-lg', posTitleClassName)}>
          {postTitleLines?.map(line => (
            <span key={line}>
              {checkIsStringAHtml(line) ? (
                <span dangerouslySetInnerHTML={{ __html: line }} />
              ) : (
                <>
                  {line}
                  <br />
                </>
              )}
            </span>
          ))}
        </h3>
      )}

      <p className='text-base font-normal leading-7'>{ctaContent}</p>

      <div className='mt-4 flex w-full flex-col flex-wrap justify-evenly gap-2 md:flex-row  md:gap-5'>
        {ctaList
          ? ctaList.map(item => (
              <p key={item.id} className=' text-base font-semibold leading-7'>
                {item.id + '.'} {item.category}
              </p>
            ))
          : ''}
      </div>
      {ctaText ? (
        <button
          className='btn btn-secondary btn-sm text-base font-medium lg:btn-md'
          onClick={ctaOnClick}>
          {ctaText}
        </button>
      ) : null}
    </div>
  )
}

export default CTABlock
