// import ROUTES from './Routes'
import { BOOK_A_MEETING_WITH_FOUNDERS_URL } from './Urls'

const data = {
  title: 'Ready to talk?',
  description:
    'Would you like to further about how we fit into your business? Book a meeting with us today.',
  // cta2: {
  //   title: 'Sign Up Now',
  //   href: ROUTES.SIGN_UP,
  //   external: false,
  // },
  cta1: {
    title: 'Book a meeting',
    href: BOOK_A_MEETING_WITH_FOUNDERS_URL,
    external: true,
  },
}

export default data
