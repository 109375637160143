import Button from 'components/atoms/Button'
import Link from 'next/link'

const DynamicCTA = ({ cta, className = '' }) => {
  if (!cta) return

  if (cta.external) {
    return (
      <a href={cta.href} target='_blank' rel='noreferrer' className={className}>
        <Button secondary className='text-md w-max uppercase'>
          {cta.title}
        </Button>
      </a>
    )
  }
  return (
    <Link href={cta.href} className={className}>
      <Button primary className='text-md w-max uppercase'>
        {cta.title}
      </Button>
    </Link>
  )
}

export default DynamicCTA
