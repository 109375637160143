// import Button from 'components/atoms/Button'
import DynamicCTA from 'components/atoms/DynamicCta'
import ReusableGrid from 'components/containers/ReusableGrid'
import Description from 'components/molecules/Description'
import Heading from 'components/molecules/Heading'
import data from 'consts/FooterCTA.js'
import React from 'react'

// The custom Title props in this comp defines that this is being used in Fees Landing Page. If it's not, then the landing page's data will be used by this comp.
const FooterCta = ({ customTitle }) => {
  return (
    <ReusableGrid className='card bg-primary-content !shadow-none'>
      <div className='col-span-full flex flex-col items-center justify-center gap-4'>
        <Heading title={customTitle || data.title} heroHeading={false} className='mx-auto' />
        <Description text={data?.description} />

        {/* <Button primary className='text-md uppercase'>
        {cta.ctaText}
      </Button> */}

        <div className='flex gap-2 md:gap-4'>
          <DynamicCTA cta={data.cta1} />
          <DynamicCTA cta={data.cta2} />
        </div>
      </div>
    </ReusableGrid>
  )
}

export default FooterCta
