import { useEffect, useState } from 'react'

// Adjust this as required, you can take it as a parameter as well. Currently, I am taking a width for tab and smaller devices.
const MOBILE_WIDTH_THRESHOLD = 1024

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < MOBILE_WIDTH_THRESHOLD)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_WIDTH_THRESHOLD)
    }

    window.addEventListener('resize', handleResize)

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return isMobile
}

export default useIsMobile
