import cn from 'clsx'
import React from 'react'

interface DescriptionModalProps {
  description: React.ReactNode
  heading: React.ReactNode
  parentClassName?: string
}

const DescriptionModal = ({
  description,
  heading,
  parentClassName = '',
}: DescriptionModalProps) => {
  return (
    <>
      <label className='cursor-pointer text-secondary underline' htmlFor='modal_description'>
        See Description
      </label>

      <input type='checkbox' id='modal_description' className='modal-toggle' />
      <div className='modal' role='dialog'>
        <div className={cn('modal-box max-w-7xl', parentClassName)}>
          <h3 className='text-lg font-bold'>{heading}</h3>
          <p className='pt-4'>{description}</p>
          <div className='modal-action'>
            <label htmlFor='modal_description' className='btn'>
              Close!
            </label>
          </div>
        </div>
        <label className='modal-backdrop' htmlFor='modal_description'>
          Close
        </label>
      </div>
    </>
  )
}

export default DescriptionModal
