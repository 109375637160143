import Image from 'components/atoms/Image'
import useIsMobile from 'hooks/useIsMobile'

const ImageCarousel = ({ images, imageWidth = 400 }) => {
  const isMobile = useIsMobile()

  return (
    <div className='carousel carousel-center space-x-4 rounded-box bg-base-200 p-4'>
      {images.map((image, index) => (
        <div className='carousel-item' key={index}>
          <Image
            src={image.src}
            className='rounded-box object-cover'
            width={isMobile ? 400 : imageWidth}
            height={500}
            alt={image.alt}
          />
        </div>
      ))}
    </div>
  )
}
export default ImageCarousel
