import cn from 'clsx'
import React from 'react'

const Heading = ({
  className = '',
  heroHeading = false,
  title = '',
  preTitle = '',
  postTitle = '',
  tooltipLabel = '',
  tooltipValue = '',
  titleClassName = '',
  ...props
}) => {
  return (
    <div className={cn('flex flex-col gap-2', className)} {...props}>
      {preTitle && <h3 className='text-lg font-normal'>{preTitle}</h3>}

      <div className='flex h-fit flex-row items-end gap-5'>
        {heroHeading ? (
          <h1 className={cn('text-4xl font-semibold', titleClassName)}>{title}</h1>
        ) : (
          <h2 className={cn('text-4xl font-semibold', titleClassName)}>{title}</h2>
        )}

        {tooltipValue && (
          <div className='tooltip h-fit font-bold text-secondary underline' data-tip={tooltipValue}>
            {tooltipLabel ?? `ℹ`}
          </div>
        )}
      </div>

      {postTitle && <h4>{postTitle}</h4>}
    </div>
  )
}

export default Heading
