import cn from 'clsx'
import Image from 'components/atoms/Image'
import Container from 'components/containers/Container'
import GridContainer from 'components/containers/GridContainer'
import SectionContainer from 'components/containers/SectionContainer'
import CTABlock from 'components/molecules/CTABlock'
import React from 'react'

const MediaText = ({
  image = { src: 'https://picsum.photos/400/300', alt: 'Picsum Placeholder' },
  cta,
  imagePosition = 'right',
  imageOverride,
  className,
  titleClassName = '',
  posTitleClassName = '',
  ctaOverride,
  hasBackground = false,
}) => {
  return (
    <SectionContainer className={cn(hasBackground ? 'bg-base-300' : '', className)}>
      <Container>
        <GridContainer className='items-center'>
          {ctaOverride || (
            <CTABlock
              className={cn(
                'order-1 col-span-full md:col-span-5',
                imagePosition === 'right' ? 'md:order-1' : 'md:order-2 md:col-start-7'
              )}
              titleClassName={titleClassName}
              posTitleClassName={posTitleClassName}
              cta={cta}
            />
          )}

          {imageOverride ? (
            <div
              className={cn(
                'order-2 col-span-full flex items-start justify-center md:col-span-6',
                imagePosition === 'right' ? 'md:order-2 md:col-start-7' : 'md:order-1'
              )}>
              {imageOverride}
            </div>
          ) : (
            <Image
              src={image?.src}
              alt={image?.alt}
              width='360'
              height='360'
              className={cn(
                'order-2 col-span-full aspect-square h-full w-full rounded-md object-cover md:col-span-5.5',
                imagePosition === 'right' ? 'md:order-2 md:col-start-6.5' : 'md:order-1',
                'custom-transition-a hover:scale-[1.02]'
              )}
            />
          )}
        </GridContainer>
      </Container>
    </SectionContainer>
  )
}

export default MediaText
