const trimString = (s, TRIM_LENGTH = 16, dots = true) => {
  if (!s || typeof s !== 'string') return ''
  else if (s.length <= TRIM_LENGTH) return s

  // Default
  return s.substring(0, TRIM_LENGTH) + (dots ? '...' : '')
}

const checkIsStringAHtml = str => {
  const htmlPattern = /<([a-z][a-z0-9]*)\b[^>]*>(.*?)<\/\1>|<([a-z][a-z0-9]*)\b[^>]*\/?>/i
  return htmlPattern.test(str)
}

export { trimString, checkIsStringAHtml }
